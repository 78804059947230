
import View from '@ckeditor/ckeditor5-ui/src/view';
import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';
import FocusTracker from '@ckeditor/ckeditor5-utils/src/focustracker';
import FocusCycler from '@ckeditor/ckeditor5-ui/src/focuscycler';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import pencilIcon from '@ckeditor/ckeditor5-core/theme/icons/pencil.svg';

export default class BoxNoticiasActionsView extends View {

    constructor( locale ) {
        super( locale );

        const t = locale.t;

        /**
         * Tracks information about DOM focus in the actions.
         *
         * @readonly
         */
        this.focusTracker = new FocusTracker();

        /**
         *
         * @readonly
         */
        this.keystrokes = new KeystrokeHandler();

        this.editButtonView = this._createButton( t( 'Editar galería' ), pencilIcon, 'edit' );

        /**
         * A collection of views that can be focused in the view.
         *
         * @readonly
         * @protected
         */
        this._focusables = new ViewCollection();

        /**
         * Helps cycling over {@link #_focusables} in the view.
         *
         * @readonly
         * @protected
         */
        this._focusCycler = new FocusCycler( {
            focusables: this._focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                // Navigate fields backwards using the Shift + Tab keystroke.
                focusPrevious: 'shift + tab',

                // Navigate fields forwards using the Tab key.
                focusNext: 'tab'
            }
        } );

        this.setTemplate( {
            tag: 'div',

            attributes: {
                class: [
                ],

                tabindex: '-1'
            },

            children: [
                this.editButtonView,
            ]
        } );
    }

    render() {
        super.render();

        const childViews = [
            this.editButtonView,
        ];

        childViews.forEach( v => {
            // Register the view as focusable.
            this._focusables.add( v );

            // Register the view in the focus tracker.
            this.focusTracker.add( v.element );
        } );

        // Start listening for the keystrokes coming from #element.
        this.keystrokes.listenTo( this.element );
    }

    /**
     * Focuses the fist {@link #_focusables} in the actions.
     */
    focus() {
        this._focusCycler.focusFirst();
    }

    _createButton( label, icon, eventName ) {
        const button = new ButtonView( this.locale );

        button.set( {
            label,
            icon,
            tooltip: true
        } );

        button.delegate( 'execute' ).to( this, eventName );

        return button;
    }
}

