import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertarNoticiasCommand from './insertarnoticiascommand';
import BoxNoticiasActionsView from "./boxnoticiasactionsview";

const FakeLabel = 'noticias-relacionadas';

export default class BoxNoticias extends Plugin {

    static get requires() {
        return [ Widget, BoxNoticiasActionsView ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'insertarNoticias', new InsertarNoticiasCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'boxNoticiasRelacionadas', {
            isObject: true,
            allowWhere: '$block',
        } );

        schema.register( 'titleNoticiasRelacionadas', {
            allowIn: 'boxNoticiasRelacionadas',
            allowContentOf: '$root',
        } );

        schema.register( 'titleSpanNoticiasRelacionadas', {
            allowIn: 'titleNoticiasRelacionadas',
            allowContentOf: '$block',
            isInline: true,
        } );

        schema.register( 'itemNoticiasRelacionadas', {
            allowIn: 'boxNoticiasRelacionadas',
            allowContentOf: '$root',
        } );

        schema.register( 'itemTitleNoticiasRelacionadas', {
            allowIn: 'itemNoticiasRelacionadas',
            allowContentOf: '$root',
        } );

        schema.register( 'entityNoticiasRelacionadas', {
            allowIn: 'itemNoticiasRelacionadas',
            allowContentOf: '$block',
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            model: 'boxNoticiasRelacionadas',
            view: {
                name: 'section',
                classes: [ 'bloque-intertext' ],
            }
        } );

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'boxNoticiasRelacionadas',
            view: {
                name: 'section',
                classes: [ 'bloque-intertext' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'boxNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'bloque-intertext' } );
                return toWidget( section, viewWriter, { label: FakeLabel } );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'titleNoticiasRelacionadas',
            view: {
                name: 'h2',
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'titleNoticiasRelacionadas',
            view: {
                name: 'h2',
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'titleNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const h2 = viewWriter.createEditableElement( 'h2' );
                return toWidgetEditable( h2, viewWriter );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'titleSpanNoticiasRelacionadas',
            view: {
                name: 'span',
                classes: [ 'text-uppercase', 'text-12', 'font-weight-bold' ],
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'titleSpanNoticiasRelacionadas',
            view: {
                name: 'span',
                classes: [ 'text-uppercase', 'text-12', 'font-weight-bold' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'titleSpanNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const span = viewWriter.createEditableElement( 'span', { class: 'text-uppercase text-12 font-weight-bold' } );
                return toWidgetEditable( span, viewWriter );
            }
        } );
        conversion.attributeToAttribute({
            model: {
                name: 'itemNoticiasRelacionadas',
                key: 'data-id'
            },
            view: {
                key: 'data-id'
            }
        });

        conversion.for( 'upcast' ).elementToElement( {
            model: 'itemNoticiasRelacionadas',
            view: {
                name: 'article',
                classes: [ 'noticia-relacionada', 'd-flex', 'align-items-center', 'mb-4' ],
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'itemNoticiasRelacionadas',
            view: {
                name: 'article',
                classes: [ 'noticia-relacionada', 'd-flex', 'align-items-center', 'mb-4' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'itemNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const id = modelElement.getAttribute('data-id');

                const article = viewWriter.createEditableElement( 'article', { 'data-id': id, class: 'noticia-relacionada d-flex align-items-center mb-4' } );
                return toWidgetEditable( article, viewWriter );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'itemTitleNoticiasRelacionadas',
            view: {
                name: 'h3',
                classes: [ 'title', 'text-20', 'w-75', 'ml-4' ],
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'itemTitleNoticiasRelacionadas',
            view: {
                name: 'h3',
                classes: [ 'title', 'text-20', 'w-75', 'ml-4' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'itemTitleNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const h3 = viewWriter.createEditableElement( 'h3', { class: 'title text-20 w-75 ml-4' }  );
                return toWidgetEditable( h3, viewWriter );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'entityNoticiasRelacionadas',
            view: {
                name: 'span',
                classes: 'entity-noticias-relacionadas',
                attributes: {
                    hidden:'hidden',
                }
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'entityNoticiasRelacionadas',
            view: {
                name: 'span',
                classes: 'entity-noticias-relacionadas',
                attributes: {
                    hidden:'hidden',
                }
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'entityNoticiasRelacionadas',
            view: ( modelElement, { writer: viewWriter } ) => {
                const span = viewWriter.createEditableElement( 'span', { class: 'entity-noticias-relacionadas', hidden: 'hidden' } );
                return toWidgetEditable( span, viewWriter );
            }
        } );
    }
}