import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertarMediasCommand extends Command {
    execute(options= {}) {
        this.editor.model.change( writer => {
            this.editor.model.insertContent( createSimpleBox( writer,options ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'boxMedias' );

        this.isEnabled = allowedIn !== null;
    }
}

function createSimpleBox( writer,options ) {
    const boxMedias = writer.createElement( 'boxMedias' );

    $(options.data).each(function (){
        const itemMedia = writer.createElement( 'itemMedia' );

        if (this.tipo === 'Imagen') {
            const img = writer.createElement('image', {src: options.path_image+this.nombre});
            writer.append(img, itemMedia);
        }
        
        writer.append( itemMedia,boxMedias );
    });

    return boxMedias;
}