import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Image from '@ckeditor/ckeditor5-image/src/image';
import BoxMedias from "./boxmedias";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
require('../../seleccionar.medias');

export default class Media extends Plugin {

    static get requires() {
        return [BoxMedias, Image,ImageToolbar, ImageStyle];
    }

    init() {
        const editor = this.editor;
        const data = $(editor.sourceElement).data('media');
        const modal = $(data.id).seleccionar_medias();

        editor.ui.componentFactory.add('media', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Media',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M608 0H160a32 32 0 0 0-32 32v96h160V64h192v320h128a32 32 0 0 0 32-32V32a32 32 0 0 0-32-32zM232 103a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm352 208a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm-168 57H32a32 32 0 0 0-32 32v288a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32zM96 224a32 32 0 1 1-32 32 32 32 0 0 1 32-32zm288 224H64v-32l64-64 32 32 128-128 96 96z"/></svg>',
                tooltip: true,
                withText: true
            });

            view.on('execute', () => {
                modal.trigger('seleccionar:mostrar');
                modal.on('seleccionar:insertar', function (event, dataModal) {
                    editor.execute('insertarMedias', {
                        'data': [dataModal],
                        'path_image':data.path_image
                    });
                });
            });

            return view;
        });
    }
}