import Command from '@ckeditor/ckeditor5-core/src/command';

const ANCLA2 = 'ancla2';

export default class Ancla2Command extends Command {
    execute(options = {}) {
        const model = this.editor.model;
        const schema = model.schema;
        const selection = model.document.selection;

        const blocks = Array.from(selection.getSelectedBlocks());

        const value = (options.forceValue === undefined) ? !this.value : options.forceValue;

        model.change(writer => {
            if (!value) {
                this._removeAncla2(writer, blocks.filter(findAncla2));
            } else {
                const blocksToAncla2 = blocks.filter(block => {
                    return findAncla2(block) || this._checkCanBeAnclado2(schema, block);
                });

                const newId = `rw-${keyAncla++}`;
                this._applyAncla2(writer, blocksToAncla2, newId);

                // Enviar el nuevo ID al servidor para guardarlo en la base de datos
                //la noticia se coge en el editar de capitulo
                this.saveAnclaToServer(newId,noticiaId);
            }
        });
    }

    _removeAncla2(writer, blocks) {
        blocks.forEach(block => {
            writer.removeAttribute('id', block);
        });
    }

    _applyAncla2(writer, blocks, id) {
        blocks.forEach(block => {
            writer.setAttribute('id', id, block);
        });
    }

    _checkCanBeAnclado2(schema, block) {
        // Verifica si el bloque puede ser anclado según el esquema
        return schema.checkChild('$root', block);
    }

    saveAnclaToServer(key, noticiaId) {
        console.log("peticion");
        fetch(`/cms/noticias/capitulo/adicionar/ancla/${noticiaId}/${key}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: key })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Ancla saved successfully:', data);
            })
            .catch(error => {
                console.error('Error saving ancla:', error);
            });
    }
}

// Función para encontrar un ancla2 en un elemento o posición.
function findAncla2(elementOrPosition) {
    return elementOrPosition.parent && elementOrPosition.parent.name === 'rw' ? elementOrPosition.parent : null;
}
