import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertSoundCite extends Command {

    constructor(editor) {
        super(editor);

        this.set("isSoundCite", false);
    }

    execute(url,start,end,plays,texto) {
        const editor = this.editor;
        const modelSelection = editor.model.document.selection;

        editor.model.change(modelWriter => {
            if (modelSelection.isCollapsed) {
                url = url || '';
                start = start || '';
                end = end || '';
                plays = plays || '';
                texto = texto || '';
                const soundCite = modelWriter.createElement('soundCite', {
                    'data-url': url,
                    'data-start': start,
                    'data-end': end,
                    'data-plays': plays,
                    'texto': texto   
                });
                editor.model.insertContent(soundCite);
                modelWriter.setSelection(soundCite, 'on');    
                             
            }
            else {
                var elm = modelSelection.getSelectedElement();
                if (elm && elm.is('element')) {
                    if (elm.hasAttribute('data-url')) {
                        modelWriter.setAttribute('class', 'soundcite soundcite-loaded soundcite-play', elm);
                        modelWriter.setAttribute('data-url', url, elm);
                        modelWriter.setAttribute('data-start', start, elm);
                        modelWriter.setAttribute('data-end', end, elm);
                        modelWriter.setAttribute('data-plays', plays, elm);     
                        modelWriter.setAttribute('texto', texto, elm);               
                     }
                }
            }
        } );
            
    }

    refresh() {
        const model = this.editor.model;
        const modelDocument = model.document;
        const elmSelected = modelDocument.selection.getSelectedElement(); 
        
        if (elmSelected) { 
            this.value = elmSelected.getAttribute('data-url');        
            this.isSoundCite = elmSelected.name == "soundCite"; 
        }
        else {
            this.value = null;
            this.isSoundCite = false;
        }

        var isAllowed = !this.isSoundCite ? modelDocument.selection.isCollapsed : true;
        if (isAllowed) {
            isAllowed = model.schema.checkChild(modelDocument.selection.focus.parent, 'soundCite');
        }

        this.isEnabled = isAllowed;
    }
}
