import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import AnclaEditing from './anclaediting';
import AnclaUI from './anclaui';

export default class Ancla extends Plugin {
    static get requires() {
        return [ AnclaEditing, AnclaUI ];
    }

    static get pluginName() {
        return 'Ancla';
    }
}