import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import BoxNoticias from './boxnoticias';
import BoxNoticiasUI from "./boxnoticiasui";
import ShowModalCommand from "./showmodalcommand"; 

require('../../seleccionar.relacionadas');

export default class NoticiasRelacionadas extends Plugin {

    static get requires() {
        return [ BoxNoticias, BoxNoticiasUI ];
    }

    init() {
        const editor = this.editor;
        const data = $(editor.sourceElement).data('noticia-relacionada');

        const modal = $(data.id).seleccionar_relacionadas();
        this.editor.commands.add( 'showModalCommand', new ShowModalCommand( this.editor, modal ) );

        editor.ui.componentFactory.add('noticiasRelacionadas', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Intertext',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><path d="M24.2480469,18.5H1.75C1.3359375,18.5,1,18.8359375,1,19.25v5C1,24.6640625,1.3359375,25,1.75,25   h22.4980469c0.4140625,0,0.75-0.3359375,0.75-0.75v-5C24.9980469,18.8359375,24.6621094,18.5,24.2480469,18.5z M23.4980469,23.5   H2.5V20h20.9980469V23.5z" fill="#1D1D1B"/><path d="M24.25,9.75H1.75C1.3359375,9.75,1,10.0859375,1,10.5v5c0,0.4140625,0.3359375,0.75,0.75,0.75h22.5   c0.4140625,0,0.75-0.3359375,0.75-0.75v-5C25,10.0859375,24.6640625,9.75,24.25,9.75z M23.5,14.75h-21v-3.5h21V14.75z" fill="#1D1D1B"/><path d="M1.75,7.5h22.4980469c0.4140625,0,0.75-0.3359375,0.75-0.75v-5c0-0.4140625-0.3359375-0.75-0.75-0.75H1.75   C1.3359375,1,1,1.3359375,1,1.75v5C1,7.1640625,1.3359375,7.5,1.75,7.5z M2.5,2.5h20.9980469V6H2.5V2.5z" fill="#1D1D1B"/></svg>',
                tooltip: true,
                withText: true
            });

            view.on('execute', () => {
                editor.execute('showModalCommand');
            });

            return view;
        });

        modal.on('seleccionar:insertar', function (event, data) {
            editor.execute('insertarNoticias', {
                'data': data
            });
        });
    }
}

