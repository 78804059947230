import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Ancla2Command from './ancla2command';

export default class Ancla2Editing extends Plugin {
    static get pluginName() {
        return 'Ancla2Editing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        // Registrar el comando de ancla2.
        editor.commands.add('ancla2', new Ancla2Command(editor));

        // Asegúrate de que el esquema permita el atributo 'id' en párrafos.
        schema.extend('$block', {
            allowAttributes: ['id']
        });

        this._defineConverters();

        // Actualizar etiquetas de ancla después de cada cambio en la vista del editor
        editor.editing.view.document.on('change', () => {
            setTimeout(updateAnclaLabels, 100); // Espera un momento para asegurar que la vista se actualice
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // Configuración de la conversión de modelo a vista para el downcast.
        conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:id:$block', (evt, data, conversionApi) => {
                const { writer, mapper } = conversionApi;
                const viewElement = mapper.toViewElement(data.item);

                if (data.attributeNewValue) {
                    writer.setAttribute('id', data.attributeNewValue, viewElement);
                    writer.addClass('rw', viewElement);

                    // Eliminar el contenido de texto existente antes de agregar el nuevo texto
                    const range = writer.createRangeIn(viewElement);
                    writer.remove(range);

                    // Insertar el nuevo texto dinámico
                    writer.insert(writer.createPositionAt(viewElement, 0), writer.createText(`rw-${data.attributeNewValue}`));
                } else {
                    writer.removeAttribute('id', viewElement);
                    writer.removeClass('rw', viewElement);
                }
            });
        });

        // Configuración de la conversión de vista a modelo para el upcast.
        conversion.for('upcast').elementToElement({
            view: {
                name: 'p',
                attributes: {
                    id: /^rw-\d+$/
                }
            },
            model: (viewElement, { writer }) => {
                const id = viewElement.getAttribute('id');
                return writer.createElement('paragraph', { id });
            }
        });
    }
}

// Función para actualizar las etiquetas de ancla dinámicamente
function updateAnclaLabels() {
    const anclaElements = document.querySelectorAll('p[id^="rw-"]');

    anclaElements.forEach(el => {
        const id = el.getAttribute('id');
        el.setAttribute('data-ancla', `rw-${id.split('-')[1]}`);
    });
}
