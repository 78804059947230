import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class SoundCiteUI extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;

        editor.ui.componentFactory.add('soundCite', locale => {
            const btn = new ButtonView(locale);
            btn.set({
                label: t('SoundCite'),
                withText: true,
                tooltip: true,
 
            });

            const soundCiteCommand = editor.commands.get('insertSoundCite');
            btn.bind('isEnabled').to(soundCiteCommand, 'isEnabled');
            btn.bind('isOn').to(soundCiteCommand, 'isSoundCite');

            this.listenTo(btn, 'execute', () => {
                editor.execute('insertSoundCite');
                this._showUI();
            });

            return btn;
        });

    }

    _showUI() {
        const editor = this.editor;

        Swal.fire({
            title: 'SoundCite Data',
            html: `<input type="text" id="data-url" class="swal2-input" placeholder="Data-url">
            <input type="text" id="data-start" class="swal2-input" placeholder="Data-start">
            <input type="text" id="data-end" class="swal2-input" placeholder="Data-end">
            <input type="text" id="data-plays" class="swal2-input" placeholder="Data-plays">
            <input type="text" id="data-texto" class="swal2-input" placeholder="Link text">`,
            customClass: 'swal-soundCite',
            confirmButtonText: 'Continuar',
            focusConfirm: false,
            preConfirm: () => {
              const url = Swal.getPopup().querySelector('#data-url').value
              const start = Swal.getPopup().querySelector('#data-start').value
              const end = Swal.getPopup().querySelector('#data-end').value
              const plays = Swal.getPopup().querySelector('#data-plays').value
              const texto = Swal.getPopup().querySelector('#data-texto').value
              if (!url || !start || !end || !plays || !texto)  {
                Swal.showValidationMessage(`Rellena todos los campos`)
              }
              return { url: url, start: start , end: end , plays: plays , texto: texto }
            }
          }).then((result) => {
                const url = result.value.url;
                const start = result.value.start;
                const end = result.value.end;
                const plays = result.value.plays;
                const texto = result.value.texto;
                editor.execute('insertSoundCite',url,start,end,plays,texto);
          });
    }
}
