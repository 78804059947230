import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import SumarioCommand from './sumariocommand';
import SumdereCommand from './sumderecommand';

const SUMARIO = 'sumario';
const SUMDERE = 'sumdere'; 

export default class SumarioEditing extends Plugin {
    static get pluginName() {
        return 'SumarioEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        editor.commands.add( SUMARIO, new SumarioCommand( editor ) );

        schema.register( SUMARIO, {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );

        editor.conversion.elementToElement( {
            model: SUMARIO,
            view: {
                name: 'div',
                classes: 'sumario'
            }
        } );

        editor.commands.add( SUMDERE, new SumdereCommand( editor ) );

        schema.register( SUMDERE, {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );

        editor.conversion.elementToElement( {
            model: SUMDERE,
            view: {
                name: 'div',
                classes: 'sumario_dere'
            }
        } );

    }
}