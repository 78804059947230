import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class Ancla2UI extends Plugin {
	init() {
		const editor = this.editor;
		editor.ui.componentFactory.add('ancla2', locale => {
			const view = new ButtonView(locale);

			view.set({
				label: 'Ancla2',
				icon: '<svg height="479pt" viewBox="-32 0 479 479.97003" width="479pt" xmlns="http://www.w3.org/2000/svg"><path d="m414.65625 290.847656-40-48c-1.566406-1.753906-3.804688-2.757812-6.160156-2.757812-2.351563 0-4.589844 1.003906-6.15625 2.757812l-40 48c-1.988282 2.386719-2.417969 5.710938-1.097656 8.523438 1.320312 2.808594 4.148437 4.605468 7.253906 4.597656h12.722656c-11.839844 48.242188-54.722656 85.441406-108.722656 94.082031v-206.082031h72c4.421875 0 8-3.582031 8-8v-32c0-4.417969-3.578125-8-8-8h-72v-20.640625c28.398437-11.484375 44.675781-41.484375 38.824218-71.554687-5.851562-30.066407-32.191406-51.773438-62.824218-51.773438-30.628906 0-56.96875 21.707031-62.820313 51.773438-5.851562 30.070312 10.425781 60.070312 38.820313 71.554687v20.640625h-72c-4.417969 0-8 3.582031-8 8v32c0 4.417969 3.582031 8 8 8h72v206.082031c-54-8.640625-96.878906-45.839843-108.71875-94.082031h12.71875c3.109375.007812 5.9375-1.789062 7.257812-4.597656 1.320313-2.8125.890625-6.136719-1.097656-8.523438l-40-48c-1.566406-1.753906-3.804688-2.757812-6.160156-2.757812-2.351563 0-4.589844 1.003906-6.15625 2.757812l-40 48c-1.988282 2.386719-2.417969 5.710938-1.097656 8.523438 1.320312 2.808594 4.148437 4.605468 7.253906 4.597656h17.84375c11.679687 74.402344 76.558594 132.878906 158.15625 142.5625v9.4375c.019531 2.121094.847656 4.15625 2.320312 5.679688l16 16c3.15625 3.09375 8.207032 3.09375 11.363282 0l16-16c1.472656-1.523438 2.300781-3.558594 2.316406-5.679688v-9.4375c81.601562-9.683594 146.480468-68.160156 158.160156-142.5625h17.839844c3.109375.007812 5.9375-1.789062 7.257812-4.597656 1.320313-2.8125.890625-6.136719-1.097656-8.523438zm-206.160156-202.878906c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24c13.257812 0 24 10.746094 24 24s-10.742188 24-24 24zm0 0"/></svg>',
				tooltip: true,
				withText: true
			});

			view.on('execute', () => {
				editor.execute('ancla2');
				editor.editing.view.focus();
			});

			return view;
		});
	}
}
