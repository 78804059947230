import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';

 
export default class EmbebedUI extends Plugin {
    init() {
        const editor = this.editor;

             // Register UI component  
        editor.ui.componentFactory.add( 'Embebed' , locale => {

            const dropdownView = createDropdown( locale );

            dropdownView.set({
                label: 'Embebed',
                tooltip: true
            });
            dropdownView.buttonView.set( {
                label: 'Embebed',
                tooltip: true,
                withText: true
            });
            dropdownView.extendTemplate( {
				attributes: {
					class: [
						'ck-image-dropdown'
					]
				}
			});

            // The collection of the list items.
            const items = new Collection();

            items.add( {
                type: 'button',
                model: new Model( {
                    id: 'desktop',
                    label: 'Desktop',
                    withText: true
                 })
            });

            items.add( {
                type: 'button',
                model: new Model( {
                    id: 'mobile',
                    label: 'Mobile',
                    withText: true
                 })
            }); 
            // Create a dropdown with a list inside the panel.
            addListToDropdown( dropdownView, items );

            // Execute command.       
            dropdownView.on('execute', (eventInfo) => {
                const { id } = eventInfo.source;

                if ( id === 'desktop' ) {
                    editor.execute('desktop');
                    editor.editing.view.focus();
                } else  {
                    editor.execute('mobile');
                    editor.editing.view.focus();
                } 
            }); 
            return dropdownView;
        } );
    }
}