import Plugin from '@ckeditor/ckeditor5-core/src/plugin'; 
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';

import TwitterCommand from './twittercommand'; 

const PluginNombre = 'twitter'; 
export default class TwitterEditing extends Plugin {
    static get pluginName() {
        return 'TwitterEditing';
    }

    init() {
		this._defineSchema();
        this._defineConverters();

		this.editor.commands.add( PluginNombre, new TwitterCommand( this.editor ) );

    }
   
	_defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( PluginNombre, {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );

      
    }

	_defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            model: PluginNombre,
            view: {
                name: 'div',
                classes: PluginNombre
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: PluginNombre,
            view: {
                name: 'div',
                classes: PluginNombre
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: PluginNombre,
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createContainerElement( 'div', { class: 'twitter' } );

                return toWidget( div, viewWriter );
            }
        } );

    }
    
}