import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertarMediasCommand from "./insertarmediascommand";

const FakeLabel = 'medias';

export default class BoxMedias extends Plugin {

    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'insertarMedias', new InsertarMediasCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'boxMedias', {
            isObject: true,
            allowWhere: '$block'
        } );
        schema.register( 'itemMedia', {
            allowIn: 'boxMedias',
            allowContentOf: '$root'
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            model: 'boxMedias',
            view: {
                name: 'section',
                classes: 'box-medias',

            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'boxMedias',
            view: {
                name: 'section',
                classes: 'box-medias'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'boxMedias',
            view: {
                name: 'section',
                classes: 'box-medias'
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'itemMedia',
            view: {
                name: 'div',
                classes: 'item-media'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'itemMedia',
            view: {
                name: 'div',
                classes: 'item-media'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'itemMedia',
            view: {
                name: 'div',
                classes: 'item-media'
            }
        } );
    }
}