import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertarNoticiasCommand extends Command {
    execute(options = {}) {
        this.editor.model.change( writer => {
            writer.model.insertContent( createSimpleBox( writer, options ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'boxNoticiasRelacionadas' );

        this.isEnabled = allowedIn !== null;
    }
}

function createSimpleBox( writer, options ) {
    const boxNoticiaRelacionada = writer.createElement( 'boxNoticiasRelacionadas' );
    const title = writer.createElement( 'titleNoticiasRelacionadas' );
    const spanTitle = writer.createElement( 'titleSpanNoticiasRelacionadas', { 'class': 'text-uppercase' } );

    writer.insertText( "Noticias Relacionadas", spanTitle, 'end' );
    writer.append( spanTitle, title );
    writer.append( title, boxNoticiaRelacionada );

    $(options.data).each(function () {
        const itemNoticiasRelacionadas = writer.createElement( 'itemNoticiasRelacionadas' );

        const img = writer.createElement('image', { src: this.image });
        writer.append( img, itemNoticiasRelacionadas );

        const itemTitleNoticiasRelacionadas = writer.createElement( 'itemTitleNoticiasRelacionadas' );

        let titular = this.titular; 
        var regex = /(<[^>]+>|<[^>]>|<\/[^>]>)/g;
        titular = titular.replace(regex , '').replace('&amp;' , '&'); 
        
        const linkTitle = writer.createText( titular );
        writer.setAttribute( 'linkHref', this.path, linkTitle );
        writer.append( linkTitle, itemTitleNoticiasRelacionadas );
        writer.append( itemTitleNoticiasRelacionadas, itemNoticiasRelacionadas );

        const entity = writer.createElement('entityNoticiasRelacionadas');
        writer.insertText( JSON.stringify(this), entity, 'end' );
        writer.append( entity, itemNoticiasRelacionadas );

        writer.append( itemNoticiasRelacionadas, boxNoticiaRelacionada );

        // const itemNoticiasRelacionadas = writer.createElement( 'itemNoticiasRelacionadas' );
        // const p = writer.createElement( 'paragraph', {} );
        // const img = writer.createElement('image', { src: this.image });
        // const entity = writer.createElement('entityNoticiasRelacionadas');
        // writer.insertText( this.titular, p, 'end' );
        // writer.insertText( JSON.stringify(this), entity, 'end' );
        // writer.append( img, itemNoticiasRelacionadas );
        // writer.append( p, itemNoticiasRelacionadas );
        // writer.append( entity, itemNoticiasRelacionadas );
        // writer.append( itemNoticiasRelacionadas, boxNoticiaRelacionada );
    });

    return boxNoticiaRelacionada;
}