import Command from '@ckeditor/ckeditor5-core/src/command';

export default class DeleteSumarioCommand extends Command {
    execute() { 
        
        const editor = this.editor;
        const modelSelection = this.editor.model.document.selection;
        
        editor.model.change(modelWriter => {
            if (modelSelection.isCollapsed) {
                return;
            }
            else { 
                var elm = modelSelection.getSelectedElement(); 
                var bloque = editor.model.schema.findAllowedParent( editor.model.document.selection.getFirstPosition(), 'boxSumario' );
                   
                if (bloque) {
                    modelWriter.remove(elm);
                }  

            }
        });
    }
}
