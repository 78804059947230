import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import IndiceCommand from './indicecommand';

const Indice = 'indice';

export default class IndiceEditing extends Plugin {
    static get pluginName() {
        return 'IndiceEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        editor.commands.add( Indice, new IndiceCommand( editor ) );

        schema.register( Indice, {
            allowWhere: '$block',
            allowContentOf: '$root'
        } );

        schema.addChildCheck( ( ctx, childDef ) => {
            if ( ctx.endsWith( Indice ) && childDef.name == Indice ) {
                return false;
            }
        } );
 
        editor.conversion.elementToElement( {
            model: Indice,
            view: {
                name: 'div',
                classes: 'new-anclas'
            }
        } ); 
    } 
}