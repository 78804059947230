import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertSumarioDerechaCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            // Insert <boxSumario>*</boxSumario> at the current selection position
            // in a way that will result in creating a valid model structure.
            this.editor.model.insertContent( createboxSumario( writer ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'boxSumarioDere' );

        this.isEnabled = allowedIn !== null;
    }
}

function createboxSumario( writer ) {
    const boxSumario = writer.createElement( 'boxSumarioDere' );
    const boxSumarioTitle = writer.createElement( 'boxSumarioTitle' );
    writer.append( boxSumarioTitle, boxSumario );

    // There must be at least one paragraph for the description to be editable.
    // See https://github.com/ckeditor/ckeditor5/issues/1464.
    writer.appendElement( 'paragraph', boxSumarioTitle );

    return boxSumario;
}
