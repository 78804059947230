import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

const ReasonWhy = 'reasonWhy';

export default class ReasonWhyUI extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add( ReasonWhy, locale => {
            const command = editor.commands.get( ReasonWhy );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: 'Reason Why',
                icon: '',
                tooltip: true,
                withText: true
            } );

            // Bind button model to command.
            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute command.
            this.listenTo( buttonView, 'execute', () => {
                editor.execute( ReasonWhy );
                editor.editing.view.focus();
            } );
            
            return buttonView;
        } );
    }
}