import Command from '@ckeditor/ckeditor5-core/src/command';
import first from '@ckeditor/ckeditor5-utils/src/first';

const SUMARIO = 'sumario';

export default class SumarioCommand extends Command {
    refresh() {
        this.value = this._getValue();
        this.isEnabled = this._checkEnabled(); 
    }

    execute( options = {} ) {
        const model = this.editor.model;
        const schema = model.schema;
        const selection = model.document.selection;

        const blocks = Array.from( selection.getSelectedBlocks() );

        const value = ( options.forceValue === undefined ) ? !this.value : options.forceValue;

        model.change( writer => {
            if ( !value ) {
                this._removeQuote( writer, blocks.filter( findQuote ) );
            } else {
                const blocksToQuote = blocks.filter( block => {
                    return findQuote( block ) || checkCanBeQuoted( schema, block );
                } );

                this._applyQuote( writer, blocksToQuote );
            }
        } );
    }

    _getValue() {
        const selection = this.editor.model.document.selection;

        const firstBlock = first( selection.getSelectedBlocks() );

        return !!( firstBlock && findQuote( firstBlock ) );
    }

    _checkEnabled() {
        if ( this.value ) {
            return true;
        }

        const selection = this.editor.model.document.selection;
        const schema = this.editor.model.schema;

        const firstBlock = first( selection.getSelectedBlocks() );

        if ( !firstBlock ) {
            return false;
        }

        return checkCanBeQuoted( schema, firstBlock );
    }

    _removeQuote( writer, blocks ) {
        getRangesOfBlockGroups( writer, blocks ).reverse().forEach( groupRange => {
            if ( groupRange.start.isAtStart && groupRange.end.isAtEnd ) {
                writer.unwrap( groupRange.start.parent );

                return;
            }

            if ( groupRange.start.isAtStart ) {
                const positionBefore = writer.createPositionBefore( groupRange.start.parent );

                writer.move( groupRange, positionBefore );

                return;
            }

            if ( !groupRange.end.isAtEnd ) {
                writer.split( groupRange.end );
            }

            const positionAfter = writer.createPositionAfter( groupRange.end.parent );

            writer.move( groupRange, positionAfter );
        } );
    }

    _applyQuote( writer, blocks ) {
        const quotesToMerge = [];

        getRangesOfBlockGroups( writer, blocks ).reverse().forEach( groupRange => {
            let quote = findQuote( groupRange.start );

            if ( !quote ) {
                quote = writer.createElement( SUMARIO );

                writer.wrap( groupRange, quote );
            }

            quotesToMerge.push( quote );
        } );

        quotesToMerge.reverse().reduce( ( currentQuote, nextQuote ) => {
            if ( currentQuote.nextSibling == nextQuote ) {
                writer.merge( writer.createPositionAfter( currentQuote ) );

                return currentQuote;
            }

            return nextQuote;
        } );
    }
}

function findQuote( elementOrPosition ) {
    return elementOrPosition.parent.name == SUMARIO ? elementOrPosition.parent : null;
}

function getRangesOfBlockGroups( writer, blocks ) {
    let startPosition;
    let i = 0;
    const ranges = [];

    while ( i < blocks.length ) {
        const block = blocks[ i ];
        const nextBlock = blocks[ i + 1 ];

        if ( !startPosition ) {
            startPosition = writer.createPositionBefore( block );
        }

        if ( !nextBlock || block.nextSibling != nextBlock ) {
            ranges.push( writer.createRange( startPosition, writer.createPositionAfter( block ) ) );
            startPosition = null;
        }

        i++;
    }

    return ranges;
}

function checkCanBeQuoted( schema, block ) {
    const isBQAllowed = schema.checkChild( block.parent, SUMARIO );
    const isBlockAllowedInBQ = schema.checkChild( [ '$root', SUMARIO ], block );

    return isBQAllowed && isBlockAllowedInBQ;
}
