import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';


const SUMARIO = 'sumario';
const SUMDERE = 'sumdere';

export default class SumarioUI extends Plugin {
    init() {
        const editor = this.editor;

             // Register UI component  
        editor.ui.componentFactory.add( SUMARIO , locale => {

            const dropdownView = createDropdown( locale );

            dropdownView.set({
                label: 'sumario',
                icon: '<svg fill="none" height="256" viewBox="0 0 256 256" width="256" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M216 41H40V65H216V41ZM40" fill="#5bc6f8" fill-rule="evenodd"/><path clip-rule="evenodd" d="M216 91H168V115H40V91ZM216 141H40V165H216V141ZM168 191H40V215H168V191Z" fill="#252525" fill-rule="evenodd"/></svg>',
                tooltip: true
            });
            dropdownView.buttonView.set( {
                label: 'Sumario',
                icon: '<svg fill="none" height="256" viewBox="0 0 256 256" width="256" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M216 41H40V65H216V41ZM40" fill="#5bc6f8" fill-rule="evenodd"/><path clip-rule="evenodd" d="M216 91H168V115H40V91ZM216 141H40V165H216V141ZM168 191H40V215H168V191Z" fill="#252525" fill-rule="evenodd"/></svg>',
                tooltip: true,
                withText: true
            });
            dropdownView.extendTemplate( {
				attributes: {
					class: [
						'ck-image-dropdown'
					]
				}
			});

            // The collection of the list items.
            const items = new Collection();

            items.add( {
                type: 'button',
                model: new Model( {
                    id: 'izq',
                    label: 'Izquierda',
                    withText: true
                 })
            });

            items.add( {
                type: 'button',
                model: new Model( {
                    id: 'dere',
                    label: 'Derecha',
                    withText: true
                 })
            }); 
            // Create a dropdown with a list inside the panel.
            addListToDropdown( dropdownView, items );

            // Execute command.       
            dropdownView.on('execute', (eventInfo) => {
                const { id } = eventInfo.source;

                if ( id === 'izq' ) {
                    editor.execute(SUMARIO);
                    editor.editing.view.focus();
                } else  {
                    editor.execute(SUMDERE);
                    editor.editing.view.focus();
                } 
            });

            return dropdownView;
        } );
    }
}