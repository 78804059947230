import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';

import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';
 
import SumarioActionsView from "./sumarioeditableactionsview"; 


export default class SumarioUI extends Plugin {
    init() {

        const editor = this.editor;
        const t = editor.t;
  
        editor.ui.componentFactory.add( 'SumarioEditable', locale => { 
 
            // The button will be an instance of ButtonView.
            const dropdownView = createDropdown( locale );
             
            dropdownView.set({
                label: t( 'Sumario' ), 
                icon: '<svg fill="none" height="256" viewBox="0 0 256 256" width="256" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M216 41H40V65H216V41ZM40" fill="#5bc6f8" fill-rule="evenodd"/><path clip-rule="evenodd" d="M216 91H168V115H40V91ZM216 141H40V165H216V141ZM168 191H40V215H168V191Z" fill="#252525" fill-rule="evenodd"/></svg>',
                tooltip: true,
                withText: true
            });
            dropdownView.buttonView.set( {
                label: t( 'Sumario' ), 
                icon: '<svg fill="none" height="256" viewBox="0 0 256 256" width="256" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M216 41H40V65H216V41ZM40" fill="#5bc6f8" fill-rule="evenodd"/><path clip-rule="evenodd" d="M216 91H168V115H40V91ZM216 141H40V165H216V141ZM168 191H40V215H168V191Z" fill="#252525" fill-rule="evenodd"/></svg>',
                tooltip: true,
                withText: true
            });
            dropdownView.extendTemplate( {
				attributes: {
					class: [
						'ck-image-dropdown'
					]
				}
			});

             // The collection of the list items.
             const items = new Collection();

             items.add( {
                 type: 'button',
                 model: new Model( {
                     id: 'izq',
                     label: 'Izquierda',
                     withText: true
                  })
             });
 
             items.add( {
                 type: 'button',
                 model: new Model( {
                     id: 'dere',
                     label: 'Derecha',
                     withText: true
                  })
             }); 
             // Create a dropdown with a list inside the panel.
             addListToDropdown( dropdownView, items );
 
            // Execute command.         
            dropdownView.on('execute', (eventInfo) => {
                const { id } = eventInfo.source;

                if ( id === 'izq' ) {
                    editor.execute('insertSumarioIzquierda');
                    editor.editing.view.focus();
                } else  {
                    editor.execute('insertSumarioDerecha');
                    editor.editing.view.focus();
                } 
            });

            return dropdownView;
            
        } );

        editor.editing.view.addObserver( ClickObserver );
  
        this._balloon = editor.plugins.get( ContextualBalloon );

        // Attach lifecycle actions to the balloon.
        this._enableUserBalloonInteractions();

        this.actionsView = this._createActionsView();
        this._startUpdatingUI();
        
    }
    destroy() {
        super.destroy();
        // Destroy created UI components as they are not automatically destroyed (see ckeditor5#1341).
        this.actionsView.destroy();
    }

    _createActionsView() { 
        const editor = this.editor;
        const actionsView = new SumarioActionsView( editor.locale );

        this.listenTo( actionsView, 'delete', () => {            
            editor.execute('deleteSumario');
            this._hideUI();
        } );

        return actionsView;
    }
    _enableUserBalloonInteractions() {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo(viewDocument, 'click', () => {
            const parentBoxSumario = this._getSelectedSumarioElement();
            if (parentBoxSumario) {
                this._showUI();
            }
        });

        // clickOutsideHandler( {
        //     emitter: this.actionsView,
        //     activator: () => this._isUIInPanel,
        //     contextElements: [ this._balloon.view.element ],
        //     callback: () => this._hideUI()
        // } );  
    }
    get _isUIInPanel() {
        return this._areActionsInPanel;
    }

    get _areActionsInPanel() {
        return this._balloon.hasView( this.actionsView );
    }

    _showUI( forceVisible = false ) { 
        this._addActionsView();
        this._startUpdatingUI();
        
    }

    _hideUI() {
        if ( !this._isUIInPanel ) {
            return;
        }

        const editor = this.editor;

        this.stopListening( editor.ui, 'update' );
        this.stopListening( this._balloon, 'change:visibleView' );

        editor.editing.view.focus();

        this._balloon.remove( this.actionsView );
    }

    _getSelectedSumarioElement() {
        const view = this.editor.editing.view;
        const selection = view.document.selection;

        if (selection.fakeSelectionLabel === 'sumarioEditable')
            return true;

        return false;
    }
    _addActionsView() {
        this._balloon.add( {
            view: this.actionsView,
            position: this._getBalloonPositionData()
        } );
    }

    _getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        const range = viewDocument.selection.getFirstRange();

        let target = view.domConverter.viewRangeToDom( range );

        return { target };
    }

    _startUpdatingUI() {
        const editor = this.editor;

        const update = () => {
            if (!this._getSelectedSumarioElement() && this._areActionsInPanel) {
                this._hideUI();
            }
        };

        this.listenTo( editor.ui, 'update', update );
        this.listenTo( this._balloon, 'change:visibleView', update );
    }
}