import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Ancla2Editing from './ancla2editing';
import Ancla2UI from './ancla2ui';
import Ancla2Command from './ancla2command';

export default class Ancla2 extends Plugin {
    static get requires() {
        return [ Ancla2Editing, Ancla2UI ];
    }

    static get pluginName() {
        return 'Ancla2';
    }

    init() {
        const editor = this.editor;

        editor.commands.add('ancla2', new Ancla2Command(editor)); // Registrar el comando
    }
}