import Command from '@ckeditor/ckeditor5-core/src/command';

export default class ShowModalCommand extends Command {

    constructor(editor, modal) {
        super(editor);
        this.modal = modal;
    }

    execute(options= {}) {
        this.modal.trigger('seleccionar:mostrar');
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'boxNoticiasRelacionadas' );

        this.isEnabled = allowedIn !== null;
    }
}