import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import TwitterEditing from './twitterediting';
import TwitterUI from './twitterui';

export default class Twitter extends Plugin {
    static get requires() {
        return [ TwitterEditing, TwitterUI ];
    }

    static get pluginName() {
        return 'Twitter';
    }
}