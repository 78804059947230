import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';
import BoxNoticiasActionsView from "./boxnoticiasactionsview";

export default class BoxNoticiasUI extends Plugin {
    /**
     * @inheritDoc
     */
    static get requires() {
        return [ ContextualBalloon ];
    }

    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'BoxNoticiasUI';
    }

    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;

        editor.editing.view.addObserver( ClickObserver );

        this.actionsView = this._createActionsView();

        this._balloon = editor.plugins.get( ContextualBalloon );

        // Attach lifecycle actions to the balloon.
        this._enableUserBalloonInteractions();

        this._startUpdatingUI();
    }

    /**
     * @inheritDoc
     */
    destroy() {
        super.destroy();
        // Destroy created UI components as they are not automatically destroyed (see ckeditor5#1341).
        this.actionsView.destroy();
    }

    _createActionsView() {
        const editor = this.editor;
        const actionsView = new BoxNoticiasActionsView( editor.locale );

        this.listenTo( actionsView, 'edit', () => {
            editor.execute('showModalCommand');
        } );

        return actionsView;
    }

    _enableUserBalloonInteractions() {
        const viewDocument = this.editor.editing.view.document;

        // Handle click on view document and show panel when selection is placed inside the link element.
        // Keep panel open until selection will be inside the same link element.
        this.listenTo( viewDocument, 'click', () => {
            const parentBoxNoticia = this._getSelectedBoxNoticiaElement();

            if ( parentBoxNoticia ) {
                this._showUI();
            }
        } );

        // Close on click outside of balloon panel element.
        clickOutsideHandler( {
            emitter: this.actionsView,
            activator: () => this._isUIInPanel,
            contextElements: [ this._balloon.view.element ],
            callback: () => this._hideUI()
        } );
    }

    get _isUIInPanel() {
        return this._areActionsInPanel;
    }

    get _areActionsInPanel() {
        return this._balloon.hasView( this.actionsView );
    }

    _showUI( forceVisible = false ) {
        this._addActionsView();
        this._startUpdatingUI();
    }

    _hideUI() {
        if ( !this._isUIInPanel ) {
            return;
        }

        const editor = this.editor;

        this.stopListening( editor.ui, 'update' );
        this.stopListening( this._balloon, 'change:visibleView' );

        editor.editing.view.focus();

        this._balloon.remove( this.actionsView );
    }

    _getSelectedBoxNoticiaElement() {
        const view = this.editor.editing.view;
        const selection = view.document.selection;

        if (selection.fakeSelectionLabel === 'noticias-relacionadas')
            return true;

        return false;
    }

    _addActionsView() {
        this._balloon.add( {
            view: this.actionsView,
            position: this._getBalloonPositionData()
        } );
    }

    _getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        const range = viewDocument.selection.getFirstRange();

        let target = view.domConverter.viewRangeToDom( range );

        return { target };
    }

    _startUpdatingUI() {
        const editor = this.editor;

        const update = () => {
            if (!this._getSelectedBoxNoticiaElement() && this._areActionsInPanel) {
                this._hideUI();
            }
        };

        this.listenTo( editor.ui, 'update', update );
        this.listenTo( this._balloon, 'change:visibleView', update );
    }
}