import Plugin from '@ckeditor/ckeditor5-core/src/plugin'; 
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

import InsertSumarioIzquierdaCommand from './sumarioeditableizquierdacommand'; 
import InsertSumarioDerechaCommand from './sumarioeditablederechacommand'; 
import DeleteSumarioCommand from './sumarioeditabledeletecommand';

const FakeLabel = 'sumarioEditable';

export default class SumarioEditing extends Plugin {
    static get requires() {
        return [ Widget ];
    }

    init() { 

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add( 'insertSumarioIzquierda', new InsertSumarioIzquierdaCommand( this.editor ) );
        this.editor.commands.add( 'insertSumarioDerecha', new InsertSumarioDerechaCommand( this.editor ) );
        this.editor.commands.add('deleteSumario', new DeleteSumarioCommand(this.editor));

    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'boxSumario', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block'
        } );

        schema.register( 'boxSumarioDere', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block'
        } );


        schema.register( 'boxSumarioTitle', {
            // Cannot be split or left by the caret.
            isLimit: true,

            allowIn: ['boxSumario', 'boxSumarioDere'],

            // Allow content which is allowed in blocks (i.e. text with attributes).
            allowContentOf: '$block'
        } );

      
        schema.addChildCheck( ( context, childDefinition ) => {
            if ( context.endsWith( 'boxSumarioTitle' ) && (childDefinition.name == 'boxSumario' || childDefinition.name == 'boxSumarioDere')) {
                return false;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        // <boxSumario> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'boxSumario',
            view: {
                name: 'section',
                classes: 'sumario'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'boxSumario',
            view: {
                name: 'section',
                classes: 'sumario'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'boxSumario',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'sumario' } );

                return toWidget( section, viewWriter, { label: FakeLabel } );
            }
        } );

        // <boxSumarioTitle> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'boxSumarioTitle',
            view: {
                name: 'p',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'boxSumarioTitle',
            view: {
                name: 'p',
                classes: 'simple-box-title'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'boxSumarioTitle',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const p = viewWriter.createEditableElement( 'p', { class: 'simple-box-title' } );

                return toWidgetEditable( p, viewWriter );
            }
        } );

        // <boxSumarioDere> converters
        conversion.for( 'upcast' ).elementToElement( {
            model: 'boxSumarioDere',
            view: {
                name: 'section',
                classes: 'sumario_dere'
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'boxSumarioDere',
            view: {
                name: 'section',
                classes: 'sumario_dere'
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'boxSumarioDere',
            view: ( modelElement, { writer: viewWriter } ) => {
                const section = viewWriter.createContainerElement( 'section', { class: 'sumario_dere' } );

                return toWidget( section, viewWriter, { label: FakeLabel } );
            }
        } );

    }
}