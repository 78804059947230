import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import DeclaracionesCommand from './declaracionescommand';
 
const DECLARACIONES = 'declaraciones';

export default class DeclaracionesEditing extends Plugin {
	static get pluginName() {
        return 'DeclaracionesEditing';
    }
	init() {
		const editor = this.editor;

		editor.model.schema.extend( '$text', { allowAttributes: DECLARACIONES } );

		editor.conversion.attributeToElement( {
			model: DECLARACIONES,
			view: {
				name: 'span',
				styles: {
					'font-style': 'italic', 
					'color': 'hsl(0, 0%, 60%)'
				}
			},
			upcastAlso: [
				{
					styles: {
						'font-style': 'italic',
						'color': 'hsl(0, 0%, 60%)'
					}
				}
			]
		} );

		editor.commands.add( DECLARACIONES, new DeclaracionesCommand( editor, DECLARACIONES ) );
	}
}
