import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ReasonWhyEditing from './reasonWhyediting';
import ReasonWhyUI from './reasonWhyui';

export default class ReasonWhy extends Plugin {
    static get requires() {
        return [ ReasonWhyEditing, ReasonWhyUI ];
    }

    static get pluginName() {
        return 'ReasonWhy';
    }
}