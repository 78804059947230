import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertarNoticiasCommand from './insertarnoticiascommand';
import BoxNoticiasActionsView from "./boxnoticiasactionsview";

const FakeLabel = 'slider-imagenes';
const PluginNombre = 'galeria';

export default class BoxNoticias extends Plugin {

    static get requires() {
        return [ Widget, BoxNoticiasActionsView ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add( 'insertarSlider', new InsertarNoticiasCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( PluginNombre, {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );
 
        schema.register( 'galeriaContainer', {
            allowIn: PluginNombre,
            allowContentOf: '$root',
            allowAttributes: [ 'id', 'data-ancho']
        } );

        schema.register( 'itemSlider', {
            allowIn: 'galeriaContainer',
            allowContentOf: '$root',
        } );

        schema.register( 'entitySlider', {
            allowIn: PluginNombre,
            allowContentOf: '$block',
        } );
      
    }

	_defineConverters() {
        const conversion = this.editor.conversion;

        // add id
        conversion.attributeToAttribute({
            model: {
                name: 'galeriaContainer',
                key: 'id'
            },
            view: {
                key: 'id'
            }
        });

        // add data-ancho
        conversion.attributeToAttribute({
            model: {
                name: 'galeriaContainer',
                key: 'data-ancho'
            },
            view: {
                key: 'data-ancho'
            }
        });

        conversion.for( 'upcast' ).elementToElement( {
            model: PluginNombre,
            view: {
                name: 'div',
                classes: PluginNombre
            }
        } );
        conversion.for( 'dataDowncast' ).elementToElement( {
            model: PluginNombre,
            view: {
                name: 'div',
                classes: PluginNombre
            }
        } );
        conversion.for( 'editingDowncast' ).elementToElement( {
            model: PluginNombre,
            view: ( modelElement, { writer: viewWriter } ) => {
                const div = viewWriter.createContainerElement( 'div', { class: 'slider' } );
                return toWidget( div, viewWriter, { label: FakeLabel } );
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'galeriaContainer',
            view: {
                name: 'div',
                classes: [ 'galeria-container' ],
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'galeriaContainer',
            view: {
                name: 'div',
                classes: [ 'galeria-container' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'galeriaContainer',
            view: ( modelElement, { writer: viewWriter } ) => {
                const id = modelElement.getAttribute( 'id' )
                const ancho = modelElement.getAttribute( 'data-ancho' )
                const item = viewWriter.createEditableElement( 'div', { class: 'galeria-container' , id , ancho} );
                return  item;
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'itemSlider',
            view: {
                name: 'div',
                classes: [ 'slider-item' ],
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'itemSlider',
            view: {
                name: 'div',
                classes: [ 'slider-item' ],
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'itemSlider',
            view: ( modelElement, { writer: viewWriter } ) => { 
                const item = viewWriter.createEditableElement( 'div', { class: 'slider-item' } );
                return item;
            }
        } );

        conversion.for( 'upcast' ).elementToElement( {
            model: 'entitySlider',
            view: {
                name: 'span',
                classes: 'entity-slider',
                attributes: {
                    hidden:'hidden',
                }
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'entitySlider',
            view: {
                name: 'span',
                classes: 'entity-slider',
                attributes: {
                    hidden:'hidden',
                }
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'entitySlider',
            view: ( modelElement, { writer: viewWriter } ) => {
                const span = viewWriter.createEditableElement( 'span', { class: 'entity-slider', hidden: 'hidden' } );
                return span;
            }
        } );

    }
}