import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import EmbebedEditing from './embebedediting';
import EmbebedUI from './embebedui';

export default class Embebed extends Plugin {
    static get requires() {
        return [ EmbebedEditing, EmbebedUI ];
    }

    static get pluginName() {
        return 'Embebed';
    }
}