import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

const PluginNombre = 'twitter';

export default class TwitterUI extends Plugin {
    init() {
        const editor = this.editor;

		editor.ui.componentFactory.add( PluginNombre, locale => {
            const command = editor.commands.get( PluginNombre );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: 'Twitter',
                tooltip: true,
                withText: true
            } );

            // Bind button model to command.
            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            // Execute command.
            this.listenTo( buttonView, 'execute', () => {
                editor.execute( PluginNombre );
                editor.editing.view.focus();
                this._showUI();
            } );
            
            return buttonView;
        } );
    }

    _showUI() {
        const editor = this.editor;

        Swal.fire({
            title: 'Twitter',
            html: `<input type="text" id="data-twitter-src" class="swal2-input" placeholder=" Twitter URL">
            <input type="text" id="data-texto" class="swal2-input" placeholder="Twitter embebed">`,
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#000',
            focusConfirm: false,
            preConfirm: () => {
              const url = Swal.getPopup().querySelector('#data-twitter-src').value 
              const texto = Swal.getPopup().querySelector('#data-texto').value
              if (!url || !texto )  {
                Swal.showValidationMessage(`Rellena todos los campos`)
              }
              return { url: url, texto: texto }
            }
          }).then((result) => {
                const url = result.value.url;
                const texto = result.value.texto;
                editor.execute(PluginNombre,url,texto);
          });
    }
}