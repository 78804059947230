"use strict";
 
const FormNoticia = function() {
    return {
		init: function() {

			FormNoticia.limitaLi();

		}, 

        limitaLi: function(idElem, numChars) {
            let elem = document.getElementById(idElem);

            if (elem) {
                elem.addEventListener('keydown', event => {               
                    let sel = window.getSelection().anchorNode;
                    let node = sel.nodeType == 3 ? sel.parentNode : sel;

                    if (node.tagName == "LI") {
                        let text = node.innerHTML.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ');
                        
                        if (text.length >= numChars) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }
                });
            }
        },
        alertaSalida: function(clase) {      

            const links = document.querySelectorAll(clase); 

            links.forEach((elem) => {
                elem.addEventListener('click', function(e) {
                    const link = this;

                    e.preventDefault();

                    Swal.fire({
                        title: '',
                        showCancelButton: true, 
                        showCloseButton: true,
                        confirmButtonText: 'Ir y Guardar los cambios',
                        cancelButtonText: 'Ir sin guardar los cambios',
                        confirmButtonColor: '#181C32',
                    }).then((result) => {    
                        //Confirm                    
                        if (result.isConfirmed) {
                            $("#noticia_guardarYSeguir").trigger("click"); 

                            setTimeout(function() {
                                window.location.href = link.href;
                            }, 200);

                        }  //Cancel             
                        else if (result.dismiss === Swal.DismissReason.cancel) {
                            window.location.href = link.href;
                        }  //Close             
                        else if (result.dismiss === Swal.DismissReason.close) {
                             
                        }
                    })               
                });
            });  
        }, 
        modalsFileInput: function(clase) {
	
            $(clase).change(function(e){
                var nextSibling = e.target.nextElementSibling;
                nextSibling.innerText = this.value.split("\\").pop();
            })
            
        }

	};
} ();

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = FormNoticia;
}