$.fn.extend({
    seleccionar_galeria:function (options) {
        let  $selections = [];

        const $this = $(this),
              $modal = $this.find('.modal'),
              $dataExtra = $this.data('extra'),
              urlFlag = "request_json=true";

        let addFlag = function (url) {
            if (url.indexOf('?') > -1) {
                url = url + "&" + urlFlag;
            } else {
                url = url + "?" + urlFlag;
            }

            return url;
        };

        let paginationRequest = function (event) {
            event.preventDefault();

            if ($(this).attr('disabled') !== 'disabled') {
                requestJson(addFlag($(this).attr('href'), "GET"));
            }
        };

        let filterRequest = function (event) {
            event.preventDefault();

            let element = $(this);
            let data = {};

            $this.find('.seleccionar-galeria-modal-filter input, .seleccionar-galeria-modal-filter select').each(function () {
                let input = $(this);

                if (input.val()) {
                    if ($.isArray(input.val())) {
                        for (let i = 0; i < input.val().length; i++) {
                            data[input.attr('name')] = input.val()[i];
                        }
                    }
                    if (!$.isArray(input.val())) {
                        data[input.attr('name')] = input.val();
                    }
                }
            });
          
            requestJson(addFlag(element.data('action')), "POST", data);
        };

        let limpiarRequest = function (event) {
            event.preventDefault();

            let url = addFlag($(this).data('action')) + '&limpiar_filter=true';

            requestJson(url, "GET");

            $this.find('.seleccionar-galeria-modal-filter input').val(null);
            $this.find('.seleccionar-galeria-modal-filter select option').prop('selected', false);
            $this.find('.seleccionar-galeria-modal-filter select').trigger('change.select2');
        };

        let loading = {
            start: function () {
                $modal.find('.modal-body').addClass('loading');
            },
            stop: function () {
                $modal.find('.modal-body').removeClass('loading');
            }
        };

        let requestJson = function (url, method, data = {}) {
            loading.start();

            $.ajax({
                url: url,
                type: method,
                data: data,
                success: function (data) {
                    let view = $(data.view);

                    // subscribe events
                    view.find('.datatable-pager a').on('click', paginationRequest);
                    view.find('table tbody td input[type=checkbox]').on('click', addOrRemoveItem);

                    $modal.find('.datatable').remove();
                    $modal.find('.modal-body').append(view);

                    updateSelection();
                },
            }).always(function () {
                loading.stop();
            });
        };

        let getSelection = function () {
            let data = [];

            $('.slider.ck-widget.ck-widget_selected span.entity-slider').each(function () {
                let datos = $(this).html();
                datos = datos.replace('&lt;p&gt;', '').replace('&lt;/p&gt;', '').replace('&amp;amp;', '&').replace('&lt;i&gt;', '').replace('&lt;/i&gt;', '');
                data.push(JSON.parse(datos));
            });
            
            return data;
        };

        let addOrRemoveItem = function () {
            let input = $(this);
            let checked = input.is(':checked');
            let value = JSON.parse(input.val());

            // Add
            if (checked) {
                let addAux = true;
                for (let i = 0; i < $selections.length; i++) {
                    if ($selections[i].id === value.id)
                        addAux = false;
                }
                if (addAux)
                    $selections.push(value);
            }

            if (!checked) {
                $selections = $.grep($selections, function (item) {
                    return value.id !== item.id;
                });
            }
        };

        let updateSelection = function () {
            $this.find('table tbody input[type=checkbox]').each(function () {
                let selectAux = JSON.parse($(this).val());
                let exist = false;

                for (let i = 0; i < $selections.length; i++) {
                    if ($selections[i].id === selectAux.id) {
                        exist = true;
                    }
                }

                if (exist) {
                    $(this).prop('checked', true);
                }
            });
        };

        $this.on('seleccionar:mostrar', function () {
            $selections = getSelection();
            console.log($selections);
            updateSelection();
            $modal.modal('show');
        });

        $modal.find('[data-toggle=accept]').on('click', function () {
            $this.trigger('seleccionar:insertar', [$selections]);
            $modal.modal('hide');
        });

        $modal.on('hidden.bs.modal', function () {
            $this.find('table tbody input:checked').prop("checked", false);
        });

        $this.find('.datatable-pager a').on('click', paginationRequest);

        $this.find('.seleccionar-galeria-modal-filter button[data-toggle=filtrar]').on('click', filterRequest);

        $this.find('.seleccionar-galeria-modal-filter button[data-toggle=limpiar]').on('click', limpiarRequest);

        $this.find('.seleccionar-galeria-modal-filter select[multiple=multiple]').select2();

        $this.find('table tbody td input[type=checkbox]').on('click', addOrRemoveItem);

        return $this;
    }
    
});
