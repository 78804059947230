import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import DesktopCommand from './desktopcommand';
import MobileCommand from './mobilecommand';
 
import { toWidget, toWidgetEditable } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

export default class EmbebedEditing extends Plugin {
    static get pluginName() {
        return 'EmbebedEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        editor.commands.add( 'desktop', new DesktopCommand( editor ) );
        editor.commands.add( 'mobile', new MobileCommand( editor ) );

        schema.register( 'desktop', {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );
        schema.register( 'mobile', {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true, 
        } );    

        editor.conversion.for( 'upcast' ).elementToElement( {
            model: 'desktop',
            view: {
                name: 'div',
                classes: 'desktop'
            }
        } );
        editor.conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'desktop',
            view: {
                name: 'div',
                classes: 'desktop'
            }
        } );
        editor.conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'desktop',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createContainerElement( 'div', { class: 'desktop' } );

                return toWidget( div, viewWriter );
            }
        } );

        editor.conversion.for( 'upcast' ).elementToElement( {
            model: 'mobile',
            view: {
                name: 'div',
                classes: 'mobile'
            }
        } );
        editor.conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'mobile',
            view: {
                name: 'div',
                classes: 'mobile'
            }
        } );
        editor.conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'mobile',
            view: ( modelElement, { writer: viewWriter } ) => {
                // Note: You use a more specialized createEditableElement() method here.
                const div = viewWriter.createContainerElement( 'div', { class: 'mobile' } );

                return toWidget( div, viewWriter );
            }
        } );
    }
}