import Command from '@ckeditor/ckeditor5-core/src/command';

const PluginNombre = 'galeria';

export default class InsertarNoticiasCommand extends Command {
    execute(options = {}) {
        this.editor.model.change( writer => {
            writer.model.insertContent( createSimpleBox( writer, options ) );
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), PluginNombre);

        this.isEnabled = allowedIn !== null;
    }
}

function createSimpleBox( writer, options ) {
    const boxSlider = writer.createElement( PluginNombre ); 
    

    $(options.data).each(function () {
        const galeriaContainer = writer.createElement( 'galeriaContainer', { id : this.id, 'data-ancho' : this.ancho} ); 
      
        this.imagenes.forEach( imagen => { 
            const galeriaItem = writer.createElement('itemSlider'); 

            let path = '/' + imagen.path; 
            let img = writer.createElement('image', { src: path });
            writer.append( img, galeriaItem );

            let pie = writer.createElement( 'paragraph', { class: 'pie-foto' } );
            writer.insertText( imagen.pie, pie, 'end' );
            writer.append( pie, galeriaItem );

            writer.append( galeriaItem, galeriaContainer );


        } );

        const inputControl = writer.createElement('entitySlider'); 
        writer.insertText( JSON.stringify(this), inputControl, 'end' );

        writer.append( inputControl, boxSlider );         
        writer.append( galeriaContainer, boxSlider );
    }); 

    return boxSlider;
}