import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ReasonWhyCommand from './reasonWhycommand';
import {viewToModelPositionOutsideModelElement}  from '@ckeditor/ckeditor5-widget/src/utils';

const ReasonWhy = 'reasonWhy';

export default class ReasonWhyEditing extends Plugin {
    static get pluginName() {
        return 'ReasonWhyEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        editor.commands.add( ReasonWhy, new ReasonWhyCommand( editor ) );

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement( this.editor.model, viewElement => viewElement.hasClass( ReasonWhy ) )
        );

        schema.register( ReasonWhy, {
            allowWhere: '$text',
            isInline: true,
            isObject: true,
            allowAttributesOf: '$text',
            allowAttributes: [ 'class']
        } );

        this._defineConverters();
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'span',
                classes: ReasonWhy
            },
            model: ( viewElement, { writer: modelWriter } ) => {

                const reasonElement = upcastReason( modelWriter );

                return  reasonElement;
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: ReasonWhy,
            view: ( modelItem, { writer: viewWriter } ) => {
                const reasonElement = createReasonElement( modelItem, viewWriter );

                return  reasonElement;
            }
        } );

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: ReasonWhy,
            view: ( modelItem, { writer: viewWriter } ) => createReasonElement( modelItem, viewWriter)
        } );

        function createReasonElement( modelItem, viewWriter ) {

            const reasonElement = viewWriter.createContainerElement( 'span', {
                class: ReasonWhy
            }, {
                isAllowedInsideAttributeElement: true
            });

            // Insert the placeholder name (as a text).
            const innerText = viewWriter.createText( 'Reason' );
            viewWriter.insert( viewWriter.createPositionAt( reasonElement, 0 ), innerText );

            const reasonElement2 = viewWriter.createContainerElement( 'span', {
                isAllowedInsideAttributeElement: true
            } );
            viewWriter.insert( viewWriter.createPositionAt( reasonElement, 2 ), reasonElement2 );

            const innerText2 = viewWriter.createText( '.' );
            viewWriter.insert( viewWriter.createPositionAt( reasonElement2, 0 ), innerText2 );

            const innerText3 = viewWriter.createText( 'Why' );
            viewWriter.insert( viewWriter.createPositionAt( reasonElement, 6 ), innerText3 );


            return reasonElement;
        }

        function upcastReason( modelWriter ) {

            const reasonElement = modelWriter.createElement( ReasonWhy, { class: ReasonWhy } );

            return reasonElement;
        }
    }
}