"use strict";

//import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Ancla from './ckeditor-plugins/ancla/ancla.plugin';
import Ancla2 from './ckeditor-plugins/ancla2/ancla2.plugin';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BalloonEditorBase from '@ckeditor/ckeditor5-editor-balloon/src/ballooneditor';
import BlockToolbar from '@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Cita from './ckeditor-plugins/cita/cita.plugin';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import Declaraciones from './ckeditor-plugins/declaraciones/declaraciones.plugin';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Embebed  from './ckeditor-plugins/embebed/embebed.plugin';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js';
import Indice from './ckeditor-plugins/indice/indice.plugin';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import Media from './ckeditor-plugins/media/media.plugin';
import Migracion from "./ckeditor-plugins/migracion/migracion.plugin";
import NoticiasRelacionadas from './ckeditor-plugins/noticias-relacionadas/noticiasrelacionadas.plugin';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import ReasonWhy from './ckeditor-plugins/reasonWhy/reasonWhy.plugin';
import SoundCite from './ckeditor-plugins/soundCite/soundCite.plugin';
import Sumario from './ckeditor-plugins/sumario/sumario.plugin';
import SumarioEditable from './ckeditor-plugins/sumarioEditable/sumarioeditable.plugin';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import Galeria from './ckeditor-plugins/galeria/noticiasrelacionadas.plugin';
import Twitter from './ckeditor-plugins/twitter/twitter.plugin';

// import WProofreader from '@webspellchecker/wproofreader-ckeditor5/src/wproofreader';

//export default class ClassicEditor extends ClassicEditorBase {}
export default class BalloonEditor extends BalloonEditorBase {}

// Editor configuration.
const config = {
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'es',
    blockToolbar: [
        'bold',
        'italic',
        'underline',
        'cita',
        'sumario',
        'indice', 
        'bulletedList',
        // 'wproofreader',
        'reasonWhy',
        'ancla',
        'ancla2'
    ],
    toolbar: {
        items: [
            'bulletedList',
        ]
    },
    image: {
        // Configure the available styles.
        styles: [
            'alignLeft', 
            'alignCenter', 
            'alignRight',
        ],

        // Configure the available image resize options.
        resizeOptions: [
            {
                name: 'imageResize:original',
                label: 'Original',
                value: null,
            },
            {
                name: 'imageResize:50',
                label: '50%',
                value: '50',
            },
            {
                name: 'imageResize:75',
                label: '75%',
                value: '75',
            }
        ],

        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
            'imageStyle:alignLeft', 
            'imageStyle:alignCenter', 
            'imageStyle:alignRight',
            '|',
            'imageResize',
            '|',
            'imageTextAlternative',
            'linkImage',
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
        ]
    },
    htmlEmbed: {
        showPreviews: true,
        sanitizeHtml: (inputHtml) => {
            let classFakeEditor = 'fake-editor';
            $('.' + classFakeEditor).remove();
            $('body').append('<div style="display: none" class="' + classFakeEditor + '">' + inputHtml + '</div>');
            return {
                html: inputHtml,
                hasChanged: false,
            };
        }
    },
    fontFamily: {
        options: [
            '"Raleway", sans-serif',
            '"Oswald", sans-serif',
            '"Playfair Display", serif',
            '"Austin News Headline Roman", serif',
        ]
    },
    fontSize: {
        options: [
            'small',
            'default',
            'big',
            'huge',
        ]
    },
    fontColor: {
        colors: [
            {
                color: 'hsl(0, 0%, 0%)',
                label: 'Negro',
            },
            {
                color: 'hsl(0, 0%, 30%)',
                label: 'Gris oscuro',
            },
            {
                color: 'hsl(0, 0%, 60%)',
                label: 'Gris',
            },
            {
                color: 'hsl(0, 0%, 90%)',
                label: 'Gris claro',
            },
            {
                color: 'hsl(0, 0%, 100%)',
                label: 'Blanco',
            },
            {
                color: 'hsl(200, 100%, 70%)',
                label: 'Azul',
            },
        ],
        columns: 3,
    },
    link: {
        decorators: {
            openInNewTab: {
                mode: 'manual',
                label: 'Nueva pestaña',
                defaultValue: false,
                attributes: {
                    target: '_blank',
                },
            },
            noFollow: {
                mode: 'manual',
                label: 'No follow',
                attributes: {
                    rel: 'nofollow',
                },
            },
        }
    },
    wordCount: {
        onUpdate: stats => {
            const wordsBox = document.querySelector( '#word-count' );
            if (wordsBox) {
                wordsBox.textContent = `${ stats.words } palabras, ${ stats.characters } caracteres.`;
            }
        }
    },
    // wproofreader: {
    //     lang: 'es_ES', // sets the default language
    //     serviceId: 'u6lNdqojYjm1uUQ', // required for the Cloud version only
    //     srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
    // },
    plugins: [
        Alignment,
        Ancla,
        Ancla2,
        Autoformat,
        Essentials,
        BlockToolbar,
        Bold,
        Cita,
        CKFinder,
        CloudServicesPlugin,
        Declaraciones,
        EasyImage,
        Embebed,
        Font,
        Heading,
        HtmlEmbed,
        Indice,
        Image,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Italic,
        Link,
        List,
        Migracion,
        Paragraph,
        PasteFromOffice,
        ReasonWhy,
        RemoveFormat,
        SoundCite,
        Sumario,
        Table,
        TableToolbar,
        TextTransformation,
        Underline,
        UploadAdapter,
        WordCount,
        SumarioEditable,
        LinkImage,
        Galeria,
        Twitter,
        // WProofreader, 
    ],
};

// TypeError: Cannot read property 'getAttribute' of null (IconView) con plugin WProofreader
// Para evitar el error modificamos el código del plugin (WProofreaderUI), añadimos el svg en icon (icon: '<svg ....')

var CKEditor = function() {
    var Plugin = {
        newCKEditor: function(elementId, config) {
            // LOAD DYNAMIC PLUGIN
            if (config.blockToolbar.indexOf('noticiasRelacionadas') > -1) {
               config.plugins.push(NoticiasRelacionadas);
            }

            if (config.blockToolbar.indexOf('media') > -1) {
                config.plugins.push(Media);
            }

            if (config.blockToolbar.indexOf('galeria') > -1) {
                config.plugins.push(Galeria);
            }

            BalloonEditor
                .create( document.getElementById( elementId ), config)
                .then( editor => {
                        $(editor.sourceElement).parents('form').on('submit',function (e) {
                            $('#'+$(editor.sourceElement).data('target-editor')).val(editor.getData());
                        }); 
                } )
                .catch( error => {
                    console.error( error );
                } );
        }
    };

    return {
		init: function () {
			$('.ckeditor-textarea').each(function() {
               Plugin.newCKEditor($(this).attr('id'),$.extend(config, JSON.parse($(this).attr('config'))));
            });
		}
	};
} ();

$(function() {
    CKEditor.init();
});
