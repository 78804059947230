import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import IndiceEditing from './indiceediting';
import IndiceUI from './indiceui';

export default class Indice extends Plugin {
    static get requires() {
        return [ IndiceEditing, IndiceUI ];
    }

    static get pluginName() {
        return 'Indice';
    }
}