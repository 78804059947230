$.fn.extend({
    seleccionar_medias:function (options){
        //Options Default
        const defaults_option = {
        };
        const $options = $.extend(defaults_option, options);
        let  $selections = [];
        const $this = $(this),
            $modal = $this.find('.modal'),
            $dataExtra = $this.data('extra'),
            $uploader = $this.find('.dropzone'),
            urlFlag = "request_json=true";

        let addFlag = function (url){
            if (url.indexOf('?')>-1) {
                url = url + "&" + urlFlag;
            } else {
                url = url + "?" + urlFlag;
            }
            return url;
        };
        let paginationRequest = function (event) {
            event.preventDefault();
            if($(this).attr('disabled')!=='disabled') {
                requestJson(addFlag($(this).attr('href'),"GET"));
            }
        };

        let filterRequest = function (event){
          event.preventDefault();
          let element = $(this);
          let data = {};
          $this.find('.seleccionar-medias-modal-filter input, .seleccionar-medias-modal-filter select').each(function (){
              let input = $(this);
              if(input.val()) {
                  if($.isArray(input.val())){
                    for(let i = 0;i<input.val().length;i++){
                        data[input.attr('name')] =  input.val()[i];
                    }
                  }
                  if(!$.isArray(input.val())){
                      data[input.attr('name')] =  input.val();
                  }
              }
          });
          requestJson(addFlag(element.data('action')),"POST",data);
        };

        let limpiarRequest = function (event){
            event.preventDefault();
            let url = addFlag($(this).data('action'))+'&limpiar_filter=true';
            requestJson(url,"GET");
            $this.find('.seleccionar-medias-modal-filter input').val(null);
            $this.find('.seleccionar-medias-modal-filter select option').prop('selected', false);
            $this.find('.seleccionar-medias-modal-filter select').trigger('change.select2');
        };

        let loading = {
            start: function (){
                $modal.find('.modal-body').addClass('loading');
            },
            stop: function (){
                $modal.find('.modal-body').removeClass('loading');
            }
        };

        let requestJson = function (url,method,data={}){
            loading.start();
            $.ajax({
                url : url,
                type : method,
                data: data,
                success : function(data)
                {
                    let view = $(data.view);

                    //subscribe events
                    view.find('.datatable-pager a').on('click',paginationRequest);
                    view.find('.seleccionar-media').on('click',seleccionarMedia);

                    $modal.find('.datatable').remove();
                    $modal.find('.modal-body').append(view);
                },

            }).always(function (){
                    loading.stop();
            });
        };

        let seleccionarMedia = function (){
            $this.trigger('seleccionar:insertar',[JSON.parse($(this).find('input').val())]);
            $modal.modal('hide');
        };

        $this.on('seleccionar:mostrar',function () {
            $modal.modal('show');
        });
        $modal.find('.seleccionar-media').on('click',seleccionarMedia);


        $this.find('.datatable-pager a').on('click',paginationRequest);

        $this.find('.seleccionar-medias-modal-filter button[data-toggle=filtrar]').on('click',filterRequest);
        $this.find('.seleccionar-medias-modal-filter button[data-toggle=limpiar]').on('click',limpiarRequest);

        $this.find('.seleccionar-medias-modal-filter select[multiple=multiple]').select2();

        $uploader.dropzone({
            url: $uploader.data('action'),
            uploadMultiple: true,
            parallelUploads: 10,
            success: function (){
                $this.find('.seleccionar-medias-modal-filter button[data-toggle=filtrar]').trigger('click');
            },
            completemultiple:function (){
                this.removeAllFiles(true);
            }
        });
 
        return $this;
    }
});