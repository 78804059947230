import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import SoundCiteEditing from './soundCiteediting';
import SoundCiteUI from './soundCiteui';

export default class Bookmark extends Plugin {
    static get requires() {
        return [SoundCiteEditing, SoundCiteUI];
    }

    static get pluginName() {
        return 'SoundCite';
    }
}
