import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import SumarioEditing from './sumarioediting';
import SumarioUI from './sumarioui';

export default class Sumario extends Plugin {
    static get requires() {
        return [ SumarioEditing, SumarioUI ];
    }

    static get pluginName() {
        return 'Sumario';
    }
}