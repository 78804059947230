import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CitaCommand from './citacommand';

const CITA = 'cita';

export default class CitaEditing extends Plugin {
    static get pluginName() {
        return 'CitaEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;

        editor.commands.add( CITA, new CitaCommand( editor ) );

        schema.register( CITA, {
            allowWhere: '$block',
            allowContentOf: '$root'
        } );

        schema.addChildCheck( ( ctx, childDef ) => {
            if ( ctx.endsWith( CITA ) && childDef.name == CITA ) {
                return false;
            }
        } );

        editor.conversion.elementToElement( { model: CITA, view: 'blockquote' } );

        editor.model.document.registerPostFixer( writer => {
            const changes = editor.model.document.differ.getChanges();

            for ( const entry of changes ) {
                if ( entry.type == 'insert' ) {
                    const element = entry.position.nodeAfter;

                    if ( !element ) {
                        continue;
                    }

                    if ( element.is( 'element', CITA ) && element.isEmpty ) {
                        writer.remove( element );

                        return true;
                    } else if ( element.is( 'element', CITA ) && !schema.checkChild( entry.position, element ) ) {
                        writer.unwrap( element );

                        return true;
                    } else if ( element.is( 'element' ) ) {
                        const range = writer.createRangeIn( element );

                        for ( const child of range.getItems() ) {
                            if (
                                child.is( 'element', CITA ) &&
                                !schema.checkChild( writer.createPositionBefore( child ), child )
                            ) {
                                writer.unwrap( child );

                                return true;
                            }
                        }
                    }
                } else if ( entry.type == 'remove' ) {
                    const parent = entry.position.parent;

                    if ( parent.is( 'element', CITA ) && parent.isEmpty ) {
                        writer.remove( parent );

                        return true;
                    }
                }
            }

            return false;
        } );
    }

    afterInit() {
        const editor = this.editor;
        const command = editor.commands.get( CITA );

        this.listenTo( this.editor.editing.view.document, 'enter', ( evt, data ) => {
            const doc = this.editor.model.document;
            const positionParent = doc.selection.getLastPosition().parent;

            if ( doc.selection.isCollapsed && positionParent.isEmpty && command.value ) {
                this.editor.execute( CITA );
                this.editor.editing.view.scrollToTheSelection();

                data.preventDefault();
                evt.stop();
            }
        } );
    }
}