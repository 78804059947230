import Command from '@ckeditor/ckeditor5-core/src/command';
const PluginNombre = 'twitter';

export default class TwitterCommand extends Command {
    execute(url,texto) {

        this.editor.model.change( writer => {
            
            const content = '<div class="raw-html-embed"><div class="lozad" data-twitter-src="' + url + '"></div><div class="notwitter">' + texto + '</div></div>'; 
            const viewFragment = this.editor.data.processor.toView( content );
            const modelFragment = this.editor.data.toModel( viewFragment );
            this.editor.model.insertContent( modelFragment, this.editor.model.document.selection );


        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), PluginNombre );

        this.isEnabled = allowedIn !== null;
    }
}

 