import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import BoxNoticias from './boxnoticias';
import BoxNoticiasUI from "./boxnoticiasui";
import ShowModalCommand from "./showmodalcommand"; 

require('../../seleccionar.galeria');
const PluginNombre = 'galeria';

export default class NoticiasRelacionadas extends Plugin {

    static get requires() {
        return [ BoxNoticias, BoxNoticiasUI ];
    }

    init() {
        const editor = this.editor;
        const data = $(editor.sourceElement).data('galeria'); 
        let modal = "";

        if (data){
            modal = $(data.id).seleccionar_galeria(); 
        }
        
        this.editor.commands.add( 'showModalSliderCommand', new ShowModalCommand( this.editor, modal ) );

        editor.ui.componentFactory.add( PluginNombre , locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Galería',
                icon: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 65.64" style="enable-background:new 0 0 122.88 65.64" xml:space="preserve"><g><path d="M9.35,21.67c0.85-0.9,2.27-0.93,3.17-0.08c0.9,0.85,0.93,2.27,0.08,3.16l-7.26,7.6l7.26,7.62c0.85,0.9,0.82,2.31-0.08,3.16 c-0.9,0.85-2.31,0.82-3.17-0.08l-8.73-9.17c-0.82-0.86-0.83-2.22,0-3.09L9.35,21.67L9.35,21.67z M40.3,52.32 c-1.39,0-2.51-1.13-2.51-2.51c0-1.39,1.12-2.51,2.51-2.51h21.96c1.39,0,2.51,1.13,2.51,2.51c0,1.39-1.12,2.51-2.51,2.51H40.3 L40.3,52.32z M40.93,12.52h41.02c1.16,0,2.22,0.48,2.98,1.24l0.01,0.01c0.77,0.77,1.24,1.82,1.24,2.98V36.9 c0,1.16-0.48,2.22-1.24,2.98l-0.01,0.01c-0.77,0.77-1.82,1.24-2.98,1.24H40.93c-1.16,0-2.22-0.48-2.98-1.24l-0.01-0.01 c-0.77-0.77-1.24-1.82-1.24-2.98V16.76c0-1.16,0.48-2.22,1.24-2.98l0.01-0.01C38.71,13,39.77,12.52,40.93,12.52L40.93,12.52z M81.71,17H41.17v19.66h40.53V17L81.71,17z M110.29,24.76c-0.85-0.9-0.82-2.31,0.08-3.16c0.9-0.85,2.31-0.82,3.17,0.08l8.73,9.14 c0.83,0.87,0.82,2.23,0,3.09l-8.73,9.17c-0.85,0.9-2.27,0.93-3.17,0.08c-0.9-0.85-0.93-2.27-0.08-3.16l7.26-7.62L110.29,24.76 L110.29,24.76z M25.31,0h72.27c1.21,0,2.31,0.5,3.11,1.3l0.01,0.01c0.8,0.8,1.29,1.9,1.29,3.11v56.81c0,1.21-0.5,2.31-1.3,3.11 l-0.01,0.01c-0.8,0.8-1.9,1.29-3.11,1.29H25.31c-1.2,0-2.31-0.5-3.11-1.3l-0.01-0.01c-0.8-0.8-1.29-1.9-1.29-3.11V4.42 c0-1.21,0.5-2.31,1.3-3.11l0.01-0.01C23,0.49,24.1,0,25.31,0L25.31,0z M97.51,4.47H25.37v56.69h72.15V4.47L97.51,4.47z"/></g></svg>',
                tooltip: true,
                withText: true
            });

            view.on('execute', () => {
                editor.execute('showModalSliderCommand');
            });

            return view;
        });

 
        if (modal){ 
            modal.on('seleccionar:insertar', function (event, data) {
                editor.execute('insertarSlider', {
                    'data': data
                });
            });
        }
       
    }
}

