import Plugin from '@ckeditor/ckeditor5-core/src/plugin';  
const Ancla = 'ancla';

export default class AnclaEditing extends Plugin {
    static get pluginName() {
        return 'AnclaEditing';
    }

    init() {
        const editor = this.editor;
        const schema = editor.model.schema;
  
        schema.register( Ancla, {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true
        } );  
    }
    afterInit() {
		const editor = this.editor;
		const conversion = editor.conversion;
		const schema = editor.model.schema;
                
                // Get information about headings from the editor's configuration.
		const headings = editor.config.get( 'heading' ).options;

       
		this._setupConversion( conversion, schema, headings );
         	 
	}

	_setupConversion( conversion, schema, headings ) {
		conversion.attributeToAttribute( { model: 'id', view: 'id' } )

                // Extend conversion only for headings.
		for ( const heading of headings ) {
			if ( heading.model.match( /^ladillo/) ) {
				schema.extend( heading.model, { allowAttributes: [ 'id' ] } );

				conversion.for( 'downcast' ).add( dispatcher => {
					dispatcher.on( `insert:${ heading.model }`, ( evt, data, conversionApi ) => {
						const modelElement = data.item;

						const id = modelElement.getAttribute( 'id' ) || `ladillo-${ Math.floor( Math.random() * 10000 ) }`;
                                                  // Set attribute on the view element
						conversionApi.writer.setAttribute( 'id', id, conversionApi.mapper.toViewElement( modelElement ) );
                                                  // Set attribute on the model element
						conversionApi.writer.setAttribute( 'id', id, modelElement );

					}, { priority: 'low' } );
				} );
			}
		}
	}
    
}