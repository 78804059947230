import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import SumarioEditing from './sumarioeditableediting';
import SumarioUI from './sumarioeditableui';
 

export default class SumarioEditable extends Plugin {
    static get requires() {
        return [ SumarioEditing, SumarioUI ];
    }
    static get pluginName() {
        return 'SumarioEditable';
    }
}
