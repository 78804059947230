import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertSoundCite from './soundCitecommand';

export default class SoundCiteEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {

        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add('insertSoundCite', new InsertSoundCite(this.editor));
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('soundCite', {
            allowWhere: '$text',
            isInline: true,
            isLimit: true,
            isObject: true,
            allowAttributes: [ 'name', 'class', 'data-url', 'data-start', 'data-end', 'data-plays', 'texto']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;
        
        //data-url
        conversion.attributeToAttribute({
            model: {
                name: 'soundCite',
                key: 'data-url'
            },
            view: {
                key: 'data-url'
            }
        });
        conversion.attributeToAttribute({
            model: {
                name: 'soundCite',
                key: 'data-start'
            },
            view: {
                key: 'data-start'
            }
        });
        conversion.attributeToAttribute({
            model: {
                name: 'soundCite',
                key: 'data-end'
            },
            view: {
                key: 'data-end'
            }
        });
        conversion.attributeToAttribute({
            model: {
                name: 'soundCite',
                key: 'data-plays'
            },
            view: {
                key: 'data-plays'
            }
        });
        conversion.attributeToAttribute({
            model: {
                name: 'soundCite',
                key: 'texto'
            },
            view: {
                key: 'texto'
            }
        });  

        conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'span',
                classes: 'soundcite'
            },
            model: ( viewElement, { writer: modelWriter } ) => {  
                const soundciteElement = upcastSoundcite( modelWriter );
                return  soundciteElement;
            }
        } );

        conversion.for( 'editingDowncast' ).elementToElement( {
            model: 'soundCite',
            view: ( modelItem, { writer: viewWriter } ) => {
                const soundciteElement = createSoundciteElement( modelItem, viewWriter ); 
                return  soundciteElement;
            }
        } );

        conversion.for( 'dataDowncast' ).elementToElement( {
            model: 'soundCite',
            view: ( modelItem, { writer: viewWriter } ) => createSoundciteElement( modelItem, viewWriter)
        } );

        function createSoundciteElement( modelItem, viewWriter ) { 

            const soundciteElement = viewWriter.createContainerElement( 'span', {
                class: 'soundcite soundcite-loaded soundcite-play'
            }, {
                isAllowedInsideAttributeElement: true
            });

            // Insert the placeholder name (as a text).  
            const texto = modelItem.getAttribute('texto');
            const innerText = viewWriter.createText( texto);
            viewWriter.insert( viewWriter.createPositionAt( soundciteElement, 0 ), innerText );

            return soundciteElement;
        }
        function upcastSoundcite( modelWriter ) { 

            const soundciteElement = modelWriter.createElement( 'soundCite', { class: 'soundcite soundcite-loaded soundcite-play' } );
            
            return soundciteElement;
        }
		
    }
}
