import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import CitaEditing from './citaediting';
import CitaUI from './citaui';

export default class Cita extends Plugin {
    static get requires() {
        return [ CitaEditing, CitaUI ];
    }

    static get pluginName() {
        return 'Cita';
    }
}