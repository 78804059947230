import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';

export default class Migracion extends Plugin {

    static get requires() {
        return [ Widget ];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'iframe', {
            allowWhere: '$block',
            allowContentOf: '$root',
            isObject: true
        } );

        schema.addAttributeCheck( context => {
            if ( context.endsWith( 'iframe' ) ) {
                return true;
            }
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            view: 'iframe',
            model: ( viewElement, { writer: modelWriter } ) => {
                return modelWriter.createElement( 'iframe', viewElement.getAttributes() );
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'iframe',
            view: 'iframe'
        } );

        conversion.for( 'downcast' ).add( dispatcher => {
            dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
                if ( data.item.name != 'iframe' ) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                const viewDiv = conversionApi.mapper.toViewElement( data.item );

                if ( data.attributeNewValue ) {
                    viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewDiv );
                } else {
                    viewWriter.removeAttribute( data.attributeKey, viewDiv );
                }
            } );
        } );
    }
}