import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import DeclaracionesEditing from './declaracionesediting';
import DeclaracionesUI from './declaracionesui';

export default class Cita extends Plugin {
    static get requires() {
        return [ DeclaracionesEditing, DeclaracionesUI ];
    }

    static get pluginName() {
        return 'Declaraciones';
    }
}